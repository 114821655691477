<template>
  <span :class="{ 'text-danger font-weight-bold': row.attention }">{{ t }}</span>
</template>
<script>
import moment from 'moment'

export default {
  props: ['value', 'row'],
  data: () => ({
  }),
  computed: {
    t () {
      let userTimezone = moment.tz.guess()
      if (this.row && this.row.timezone && !this.row.ignore_timezone)
        userTimezone = this.row.timezone
      if (this.value)
      {
        return moment.tz(this.value, userTimezone).format('MM-DD-YYYY hh:mm A')
      } else {
        return ''
      }
    }
  }
}
</script>
