<template>
  <div class="center">
    <router-link :to="{name: 'editAgency', params: {id: row.id}}" class="btn btn-default btn-with-icon"><div class="btn-with-icon-content"><i class="fa fa-edit"></i></div></router-link>
    <!-- <button @click="edit(row.id)" class="btn btn-default btn-with-icon"><div class="btn-with-icon-content"><i class="fa fa-edit"></i></div></button> -->
    <button @click="impersonate(row.user.id)" class="btn btn-default btn-with-icon"><div class="btn-with-icon-content"></div></button>

    <span class="actions dropdown actions-dropdown" v-dropdown>
      <button class="btn btn-default btn-with-icon dropdown-toggle">
        <div class="btn-with-icon-content"><i class="ion ion-md-more"></i></div>
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div class="dropdown-menu-content">
          <a class="dropdown-item" v-for="action in actions" @click="selectAction(action)">{{action.label}}</a>
        </div>
      </div>
    </span>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested'],
  mounted () {
    $(this.$el).find('button[title]').tooltip()
  },

  data () {
    return {
      actions: [
        {
          label: 'Do some thing #1',
          callback: this.actionCallback,
        },
        {
          label: 'Do some thing #2',
          callback: this.actionCallback,
        }
      ]
    };
  },

  computed: {
    isDisplayRowVisible () {
      if (this.nested.comp !== 'DisplayRow') return
      return this.nested.visible
    },
    isFriendsTableVisible () {
      if (this.nested.comp !== 'FriendsTable') return
      return this.nested.visible
    }
  },
  methods: {
    edit (id) {
      this.$router.push(`/business/edit/${id}`)
    },
    toggleNestedComp (comp) {
      const { nested } = this
      if (nested.comp === comp) return nested.$toggle()
      nested.$toggle(comp, true)
    },
    impersonate (id) {
      this.$store.state.app.isLoading = true
      this.$store.dispatch('auth/impersonate', id).then(success => {
        sessionStorage.setItem('createdBusinessId', id)
        this.$store.state.app.isLoading = false
      }, error => {
        this.$store.state.app.isLoading = false
      }).catch(error => {
        this.$store.state.app.isLoading = false
      })
    },

    selectAction (action) {
      action.callback()
    },

    //TODO: replace this dummy function with actuall actions
    actionCallback() {
      window.setTimeout(() => {
        alert('In development, here actual action will be implemented in the future');
      }, 50);

    },
  }
}
</script>
<style>
.-nested-comp-open-btn {
  color: #fff !important;
  background-color: #337ab7 !important;
}
</style>
