<template>
     <span class="nestedvalue">
      {{value}}
    </span>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment-timezone'

  export default {
    props: ["row", "field"],
    computed: {
      value() {
        const v = moment.utc(_.get(this.row, this.field)).local();
        if (v) {
          return v.format('MM/DD/YYYY hh:mm A')
        }
        return ''
      }
    }
  };
</script>

<style scoped>

</style>
