<template>
  <VuesticPopover
    :popoverClass="customPopoverClass"
    :placement="placement"
    :auto-hide="autoHide"
    :open="open"
  >
    <span slot="trigger" class="trigger-span">
      <slot v-if="isTriggerPresent" name="trigger">
      </slot>
      <span v-else>
        {{ displayValue }}
      </span>
      <span class="arrow-span" v-if="showArrow"><i class="fa fa-angle-down"></i></span>
    </span>
    <span slot="body">
      <ul class="options" :class="{'selectable': selectable}">
        <li
          v-close-popover="true"
          v-for="(item, index) in options"
          :key="index"
          :class="{
            'selected': isItemSelected(item),
            'disabled': item.disabled === true
          }"
          @click="selectItem(item)"
        >
          <i v-if="item.icon" :class="item.icon"></i>
          <span v-if="item.disabled" v-b-tooltip.hover :title="item.hint"> {{ item.label }}</span>
          <span v-else> {{ item.label }}</span>
        </li>
      </ul>
    </span>
  </VuesticPopover>
</template>

<script>

import VuesticPopover from '../../../vuestic-theme/vuestic-components/vuestic-popover/VuesticPopover';

export default {
  name: "app-dropdown-select",
  
  components: {
    VuesticPopover
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    options: {
      type: Array,
      default: []
    },
    placement: {
      type: String,
      default: 'auto'
    },
    popoverClass: {
      type: String,
      default: ''
    },
    autoHide: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      selectedItem: null,
    }
  },

  mounted () {
    this.initSeletecItem();
  },

  watch: {
    value: {
      handler (value) {
        this.selectedItem = this.options.find(item => item == value)
      },
      immediate: true,
    }
  },

  methods: {
    initSeletecItem() {
      if (this.selectable && this.options.length)
      {
        this.selectedItem = this.options[0]
      }
    },

    isItemSelected (item) {
      return this.selectable && this.selectedItem && this.selectedItem.value === item.value
    },

    selectItem (item) {
      if (item.disabled === true)
        return false

      this.selectedItem = item
      this.open = false
      this.$emit('input', item)
    },
  },
  
  computed: {
    customPopoverClass() {
      return 'custom-dropdown ' + this.popoverClass
    },
    isTriggerPresent () {
      return !!this.$slots.trigger
    }, 
    displayValue() {
      if (this.selectedItem)
        return this.selectedItem.label
      return ''
    }
  }
};
</script>

<style lang='scss' scoped>
.trigger-span {
  cursor: pointer;
  color: inherit;
  font-weight: bold;
  display: inline-block;
  span.arrow-span {
    padding-left: 8px;
  }
}
</style>
