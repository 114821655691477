<template>
  <span>{{ content }}</span>
</template>
<script>
import moment from 'moment'

export default {
  props: ['value', 'row'],
  data: () => ({
  }),
  computed: {
    content () {
      if (this.value)
      {
        if (this.value.length > 30)
        {
          return this.value.substring(0, 100) + '...'
        }
      }
      return this.value
    }
  }
}
</script>
