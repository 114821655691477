<template>
  <div class="html-wrapper" v-html="value" v-linkified></div>
</template>
<script>
export default {
  props: ['value']
}
</script>
<style lang="scss">
.html-wrapper {
  max-height: 65px;
  overflow-y: auto;
}
</style>
