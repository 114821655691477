<template>
     <span class="nestedvalue">
      {{value}}
    </span>
</template>

<script>
  import _ from 'lodash';

  export default {
    props: ["row", "field"],
    computed: {
      value() {
        return _.get(this.row, this.field)
      }
    }
  };
</script>

<style scoped>
  .nestedvalue {
    word-break: break-all;
  }
</style>
