<template>
  <a v-b-tooltip.hover :title="value.hover"><span class="status" v-bind:class="{'completed': value.text == 'completed'}">{{ formatedValue }}</span></a>
</template>

<script>
  export default {
    name: "CallStatus",
    props: {
      value: {
        type: Object,
        default: {}
      },
    },
    computed: {
      formatedValue() {
        return this.value.text
      }
    }
  }
</script>

<style lang="scss" scoped>
  span.status {
    text-transform: capitalize;
    color: #aeafae;
    &.completed {
      color: green;
    }
  }
</style>
