<template>
  <div class="center">
    <AppDropdownSelect
      :options="moreOptions"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="auto"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>

import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect';

export default {
  components: {
    AppDropdownSelect,
  },

  props: ['row', 'nested', 'xprops'],

  data () {
    return {
    }
  },

  mounted() {
  },

  computed: {
    moreOptions() {
      return [
        { icon: 'fa fa-eye', label: 'Detail', value: 'detail' },
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit' },
        { icon: 'fa fa-send', label: 'Send a Message', value: 'sendMessage' },
        { icon: this.row.opt_out ? 'fa fa-sign-in': 'fa fa-sign-out', label: this.row.opt_out ? 'Opt In' : 'Opt Out', value: 'opt', disabled: !!this.row.twilio_opt_out, hint: "We can't Opt In this contact as customer unsubscribed on his/her side." },
        { icon: 'fa fa-trash', label: 'Delete', value: 'delete'  },
      ]
    }
  },

  methods : {
    onItemClick(item) {
      switch (item.value) {
        case 'opt':
          this.opt(this.row)
          break;
        case 'detail':
          this.detail(this.row)
          break;
        case 'edit':
          this.edit(this.row)
          break;
        case 'sendMessage':
          this.sendMessage(this.row)
          break;
        case 'delete':
        default:
          this.delete(this.row)
          break;
      }
    },

    detail (contact) {
      this.xprops.eventbus.$emit('onDetailContact', contact)
    },

    edit (contact) {
      this.xprops.eventbus.$emit('onEditContact', contact)
    },

    sendMessage (contact) {
      this.xprops.eventbus.$emit('onSendMessage', contact)
    },

    delete (contact) {
      this.xprops.eventbus.$emit('onDeleteContact', contact)
    },

    opt (contact) {
      this.xprops.eventbus.$emit('onOptContact', contact)
    },
  }
}
</script>
<style lang="scss">
  .more-custom-popover {
    ul li {
      font-size: 17px;
      line-height: 30px !important;
      &:hover {
        border-bottom: 1px solid $rb-blue;
      }
    }
  }
</style>
<style scoped lang="scss">
  .more-icon {
    display: block;
    padding: 0 5px;
  }
  .custom-popover {
    ul.li {
      font-size: 17px;
      line-height: 30px !important;
    }
  }
</style>
