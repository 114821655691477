<template>
  <div class="d-flex justify-content-start">
    <span class="tag" v-for="item in formattedValue">
      {{ item.name }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "Tags",
    props: {
      value: {
        type: Array,
        default: []
      },
    },
    computed: { 
      formattedValue() {
        if (!this.value)
          return []
        return this.value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tag {
    background-color: #3578c6;
    color: #fff;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: .85em;
    display: block;
    margin-right: 5px;
  }  
</style>
