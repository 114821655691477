<template>
  <div>
    <ul>
      <li v-for="item in showList">
        {{ item.label }} : <b>{{ formatValue(item.value) }}</b>
      </li>
    </ul>
    <VuesticPopover :autoHide="true" :showArrow="false" placement="right" popoverClass="plan-meta-popover"
      :open="openValue" @onUpdateOpen="onUpdateOpen">
      <span slot="trigger" class="trigger-span">
        <u>More...</u>
      </span>
      <span slot="body">
        <ul>
          <li v-for="item in formattedValue" v-if="item.visible">
            {{ item.label }} : <b>{{ formatValue(item.value) }}</b>
          </li>
        </ul>
      </span>
    </VuesticPopover>
  </div>
</template>

<script>
import VuesticPopover from '../../../../vuestic-theme/vuestic-components/vuestic-popover/VuesticPopover';
import { agencyStripePlanMetadata } from '@/constants'
export default {
  name: "Json",
  components: {
    VuesticPopover
  },
  props: {
    value: {
      type: Object,
      default: {}
    },
  },

  data() {
    return {
      openValue: false,
    }
  },

  computed: {
    formattedValue() {
      if (!this.value)
        return []
      let metadata = JSON.parse(JSON.stringify(agencyStripePlanMetadata))
      let res = []

      metadata.forEach(item => {
        if (this.value.hasOwnProperty(item.key)) {
          res.push({
            ...item,
            value: this.value[item.key]
          })
        }
      })
      return res;
    },
    showList() {
      return this.formattedValue.filter(item => {
        return ['Calls', 'Messages'].includes(item.key)
      })
    }
  },
  methods: {
    onUpdateOpen(val) {
      this.openValue = val;
    },

    formatValue(value) {
      if (value === 0 || value === '0')
        return 'Unlimited'
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
}

.trigger-span {
  cursor: pointer;
}
</style>