<template>
  <div class="d-flex justify-content-center">
    <span class="count-link" @click="onLinkClick()">{{ value }}</span>
  </div>
</template>

<script>
  export default {
    name: "TaggedContactCount",
    props: {
      value: {
        default: 0
      },
      xprops: Object,
      row: Object,
    },
    computed: { 
    },
    methods: {
      onLinkClick() {
        this.xprops.eventbus.$emit('onLinkClick', this.row);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .count-link {
    color: #3578c6;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    };
  }  
</style>
