<template>
    <span class="propertyvalue">
    {{value}}
    </span>
</template>

<script>
import _ from 'lodash';
export default {
  props: ["row", "field"],
  computed: {
    value() {
      return _.get(this.row.properties,this.field)
    }
  }
};
</script>

<style lang='scss' scoped>
  .propertyvalue {
    word-break: break-word;
  }
</style>
