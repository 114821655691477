<template>
  <ul>
    <li v-for="item in formattedValue">
      {{ item.key }} : {{ item.value }}
    </li>
  </ul>
</template>

<script>
  export default {
    name: "Json",
    props: {
      value: {
        type: Array,
        default: []
      },
    },
    computed: { 
      formattedValue() {
        if (!this.value)
          return []
        return this.value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    margin-bottom: 0;
  }
</style>
