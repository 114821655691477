<template>
  <span>{{ t }}</span>
</template>
<script>
import moment from 'moment'

export default {
  props: ['value', 'row'],
  data: () => ({
  }),
  computed: {
    timezones() {
      return this.$store.getters['timeZones']
    },
    t () {
      let userTimezone = moment.tz.guess()
      if (this.row && this.row.timezone)
        userTimezone = this.row.timezone
      const timezone = this.timezones.find(item => item.value === userTimezone)
      if (timezone)
      {
        return timezone.label
      } else {
        return ''
      }
    }
  }
}
</script>
