<template>
  <div class="d-flex justify-content-center">
    <span class="count-link" @click="onTagLinkClick()">{{ formattedValue.length }}</span>
  </div>
</template>

<script>
  export default {
    name: "TagsCount",
    props: {
      value: {
        type: Array,
        default: []
      },
      xprops: Object,
      row: Object,
    },
    computed: { 
      formattedValue() {
        if (!this.value)
          return []
        return this.value;
      }
    },
    methods: {
      onTagLinkClick() {
        this.xprops.eventbus.$emit('onTagLinkClick', this.row);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .count-link {
    color: #3578c6;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    };
  }  
</style>
