<template>
  <span>{{ formatedValue }}</span>
</template>

<script>
  export default {
    name: "Phone",
    props: {
      value: {
        type: String,
        default: ''
      },
    },
    computed: {
      formatedValue() {
        let result = 'CMS';
        switch(this.value)
        {
          case 'sms':
            result = 'SMS';
            break;
          case 'voice':  
            result = 'Voice';
            break;
          default:
            result = 'CMS';
            break;
        }
        return result;
      }
    }
  }
</script>

<style scoped>

</style>
