<template>
  <span>{{ t }}</span>
</template>
<script>
import moment from 'moment'

export default {
  props: ['value', 'xprops'],
  computed: {
    t () {
      if (!this.value)
        return ''
      const t = moment(this.value)
      return t.format('MM-DD-YYYY')
    }
  }
}
</script>
