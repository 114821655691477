<template>
  <div class="text-center">
    <i class="fa fa-check" style="font-size:22px" v-if="value"></i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      requred: true,
    }
  },
}
</script>
