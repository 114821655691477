<template>
  <vuestic-switch v-model="mutableValue" :disabled="disabled" :offcolor=true style="max-width: 300px; margin: auto;">
    <span slot="trueTitle">On</span>
    <span slot="falseTitle">Off</span>
  </vuestic-switch>
</template>
<script>
  export default {
    name: 'AccountsActiveToggleTd',
    props: {
      value: Boolean,
      xprops: Object,
      row: Object,
    },
    data() {
      return {
        mutableValue: Boolean(this.value)
      }
    },
    computed: {
      disabled() {
        return false
      },
    },
    watch:  {
      mutableValue: function(val) {
        this.xprops.eventbus.$emit('toggleActive',{id: this.row.id, active: this.mutableValue});
      }
    }
  };
</script>
