<template>
  <div class="center">
    <AppDropdownSelect
      :options="moreOptions"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="bottom"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>

import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect';

export default {
  components: {
    AppDropdownSelect,
  },

  props: ['row', 'nested', 'xprops'],

  data () {

    return {
      moreOptions: [
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit', link: 'editAgency' },
      ],
    }
  },

  mounted() {
    if (this.row.status == 'active')
    {
      this.moreOptions.push({ icon: 'fa fa-trash', label: 'Delete', value: 'delete', link: null })
    }
  },

  methods : {
    onItemClick(item) {
      if (item.value === 'delete')
      {
        this.archive(this.row)
      } else {
        this.$router.push({ name: item.link, params: {id: this.row.id} })
      }
    },

    archive (agency) {
      this.xprops.eventbus.$emit('archiveAgency', agency)
    },
  }
}
</script>
<style lang="scss">
  .more-custom-popover {
    ul li {
      font-size: 17px;
      line-height: 30px !important;
      &:hover {
        border-bottom: 1px solid $rb-blue;
      }
    }
  }
</style>
<style scoped lang="scss">
  .more-icon {
    display: block;
    padding: 0 5px;
  }
  .custom-popover {
    ul.li {
      font-size: 17px;
      line-height: 30px !important;
    }
  }
</style>
