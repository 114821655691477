<template>
  <div class="center">
    <AppDropdownSelect
      :options="moreOptions"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="bottom"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>

import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect';

export default {
  components: {
    AppDropdownSelect,
  },

  props: ['row', 'nested', 'xprops'],

  data () {

    return {
      moreOptions: [
        { icon: 'fa fa-eye', label: 'View', value: 'view' },
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit' },
        { icon: 'fa fa-copy', label: 'Duplicate', value: 'duplicate' },
        { icon: 'fa fa-line-chart', label: 'Report', value: 'report' },
        { icon: 'fa fa-refresh', label: 'Refresh Stats', value: 'refresh' },
      ],
    }
  },

  mounted() {
    if (this.row.status === 'paused') {
      this.moreOptions.unshift({ icon: 'fa fa-play', label: 'ReRun', value: 'rerun'  })
    }
    if (this.row.status !== 'in-progress') {
      this.moreOptions.push({ icon: 'fa fa-trash', label: 'Delete', value: 'delete'  })
    } else {
      this.moreOptions.unshift({ icon: 'fa fa-stop-circle-o', label: 'Stop', value: 'stop'  })
    }
  },

  methods : {
    onItemClick(item) {
      switch (item.value) {
        case 'view':
          this.view(this.row)
          break;
        case 'rerun':
          this.rerun(this.row)
          break;
        case 'edit':
          this.edit(this.row)
          break;
        case 'duplicate':
          this.duplicate(this.row)
          break;
        case 'report':
          this.report(this.row)
          break;
        case 'stop':
          this.stop(this.row)
          break;
        case 'delete':
          this.delete(this.row)
          break;
        case 'refresh':
          this.refresh(this.row)
          break;
        default:
          break;
      }
    },

    rerun (row) {
      this.xprops.eventbus.$emit('onReRun', row)
    },

    view (row) {
      this.xprops.eventbus.$emit('onView', row)
    },

    edit (row) {
      this.xprops.eventbus.$emit('onEdit', row)
    },

    duplicate (row) {
      this.xprops.eventbus.$emit('onDuplicate', row)
    },

    delete (row) {
      this.xprops.eventbus.$emit('onDelete', row)
    },

    stop (row) {
      this.xprops.eventbus.$emit('onStop', row)
    },

    report (row) {
      this.xprops.eventbus.$emit('onReport', row)
    },

    refresh (row) {
      this.xprops.eventbus.$emit('onRefresh', row)
    },
  },
}
</script>
<style lang="scss">
  .more-custom-popover {
    ul li {
      font-size: 17px;
      line-height: 30px !important;
      &:hover {
        border-bottom: 1px solid $rb-blue;
      }
    }
  }
</style>
<style scoped lang="scss">
  .more-icon {
    display: block;
    padding: 0 5px;
  }
  .custom-popover {
    ul.li {
      font-size: 17px;
      line-height: 30px !important;
    }
  }
</style>
