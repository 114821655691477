<template>
  <div class="center">
    <button @click="impersonate(row.user.id)" class="ml-2 btn btn-default" v-if="row.status == 'active'">
      <i class="fa fa-sign-in"></i> Login
    </button>
    <template v-if="row.status == 'archived'">
    <button class="btn btn-default btn-with-icon" @click="restore(row)" v-b-tooltip.hover title="Restore This Account">
      <div class="btn-with-icon-content"><i class="fa fa-undo"></i></div>
    </button>
    <button class="btn btn-default btn-with-icon" @click="onDelete(row)" v-b-tooltip.hover title="Delete This Account">
      <div class="btn-with-icon-content"><i class="fa fa-trash text-danger"></i></div>
    </button>
    </template>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],

  data () {
    return {
      disableArchive: false,
    }
  },

  mounted () {
    $(this.$el).find('button[title]').tooltip();
    var agency_id = localStorage.getItem('agencyId')
  },
  computed: {
    
    isDisplayRowVisible () {
      if (this.nested.comp !== 'DisplayRow') return null
      return this.nested.visible
    },
    isFriendsTableVisible () {
      if (this.nested.comp !== 'FriendsTable') return null
      return this.nested.visible
    }
  },
  methods: {
    toggleNestedComp (comp) {
      const { nested } = this
      if (nested.comp === comp) return nested.$toggle()
      nested.$toggle(comp, true)
    },
    impersonate (id) {
      this.$store.dispatch('auth/impersonate', id).then(success => {
      }).catch(error => {
        console.log('Error', error);
      });
    },

    archive (agency) {
      this.xprops.eventbus.$emit('archiveAgency', agency)
    },

    onDelete (agency) {
      this.xprops.eventbus.$emit('deleteAgency', agency)
    },

    restore (agency) {
      this.xprops.eventbus.$emit('restoreAgency', agency)
    },
  },
}
</script>
<style scoped="">
.btn {
  color: #3578c6;
}
.-nested-comp-open-btn {
  color: #fff !important;
  background-color: #337ab7 !important;
}
</style>
