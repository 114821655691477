<template>
  <vuestic-switch v-model="mutableValue" style="max-width: 300px; margin: auto" :offcolor=true>
    <span slot="trueTitle">On</span>
    <span slot="falseTitle">Off</span>
  </vuestic-switch>
</template>
<script>
  export default {
    name: 'InstallsToggleActive',
    props: {
      xprops: Object,
      row: Object,
    },
    data() {
      return {
        mutableValue: Boolean(this.row.active)
      }
    },
    computed: {
    },
    watch:  {
      mutableValue: function(val) {
        this.xprops.eventbus.$emit('toggle',{id: this.row.id, active: this.mutableValue});
      }
    }
  };
</script>
