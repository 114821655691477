<template>
  <div class="d-flex align-items-center justify-content-center  ">
    <span class="d-block">{{ formatedValue }}</span>
    <span v-if="!isValid" class="d-block ml-2 alert-span" v-b-tooltip.hover title="Phone Number is invalid" ><i class="text-danger fa fa-exclamation-circle"></i></span>
    <span v-if="showSendMessage" class="send-icon badge badge-primary ml-2" v-b-tooltip.hover title="Send a Message" @click="onSendMessage"><i class="text-white fa fa-comment"></i></span>
  </div>
</template>

<script>
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  export default {
    name: "Phone",
    props: ['value', 'row', 'xprops'],
    computed: {
      formatedValue() {
        let tmp = this.value;
        if (!tmp)
          return '';
        if (!tmp.startsWith('+'))
          tmp = '+' + tmp;

        let phoneNumber = parsePhoneNumberFromString(tmp)
        try {
          phoneNumber = phoneNumber.formatNational()
        } catch (error) {
          phoneNumber = tmp
        }
        
        return phoneNumber
      }, 
      isValid() {
        return !this.value || this.isValidNumber(this.value)
      },
      showSendMessage() {
        return this.isValid && this.user && !['1','2'].includes(this.user.user_type)
      },
      user() {
        return this.$store.state.auth.user;
      },
    },
    methods: {
      onSendMessage () {
        this.xprops.eventbus.$emit('onSendMessage', this.row)
      },
    }
  }
</script>

<style lang="scss">
  .contact-table,
  .task-table {
    .send-icon {
      display: inline-block !important;
    }
  }
</style>

<style lang="scss" scoped>
  .alert-span {
    font-size: 20px;
  }
  .send-icon {
    display: none;
    cursor: pointer;
  }
</style>
