<template>
  <div v-if="!row.is_template">
    <ul>
      <li>Number of Installs : <b>{{ row.number_of_installs }}</b></li>
    </ul>
    <VuesticPopover :autoHide="true" :showArrow="false" placement="right" popoverClass="plan-meta-popover"
      :open="openValue" @onUpdateOpen="onUpdateOpen">
      <span slot="trigger" class="trigger-span">
        <u>More...</u>
      </span>
      <span slot="body">
        <ul>
          <li>User Name : <b>{{ userName }}</b></li>
          <li>Email : <b>{{ row.user.email }}</b></li>
          <li>Plan : <b>{{ plan ? plan.label + ` (${plan.interval}ly)` : 'N/A' }} </b></li>
          <li>Plan Type : <b>{{ planType }} </b></li>
          <li>Price : <b>{{ plan ? `$${ plan.amount_org}` : 'N/A' }}</b></li>
          <li>Status : <b>{{ status }}</b></li>
          <li>{{ planType === 'Opt In' ? 'Trial Start' : 'Created At' }} : <b>{{ formatTime(row.created_at) }}</b></li>
          <li v-if="planType === 'Opt In'">Trial End : <b>{{ formatTime(row.trial_ends_at) }}</b></li>
          <li v-if="row.user.pm_last_four">Card Last Four: <b>{{ `(${row.user.pm_type}) ${row.user.pm_last_four}` }}</b></li>
          <li v-if="row.last_access_at">Last Access At : <b>{{ formatTime(row.last_access_at) }}</b></li>
          <li v-if="hasSurvey">
            Survey : 
            <ul style="margin-left: 10px">
              <li>Number of Businesses : <b>{{ row.properties.totalBusiness }}</b></li>
              <li>Business Goals : <b>{{ row.properties.goalBusiness }}</b></li>
              <li>Number of People : <b>{{ row.properties.totalPeople }}</b></li>
            </ul>
          </li>
        </ul>
      </span>
    </VuesticPopover>
  </div>
</template>

<script>
import moment from 'moment'
import VuesticPopover from '../../../../vuestic-theme/vuestic-components/vuestic-popover/VuesticPopover';

export default {
  name: "Json",
  components: {
    VuesticPopover
  },
  props: {
    row: Object,
  },

  data() {
    return {
      openValue: false,
    }
  },

  computed: {
    plans() {
      return this.$store.getters['subscription/plans']
    },

    plan() {
      let currentPlan = this.row.stripe_plan ? this.row.stripe_plan : this.row.default_plan
      return this.plans.find(item => item.id === currentPlan)
    },

    planType() {
      let res = 'Opt Out'
      if (this.row.trial_ends_at)
      {
        let startAt = moment(this.row.created_at)
        let endAt = moment(this.row.trial_ends_at)
        if (!startAt.isSame(endAt, 'day'))
        {
          res = 'Opt In'
        }
      }
      return res
    },

    hasSurvey() {
      return this.row.properties && this.row.properties.totalBusiness
    },
    
    userName() {
      return this.row.user.first_name + (this.row.user.last_name ? ' ' + this.row.user.last_name : '')
    },

    status() {
      let res = 'Active'
      if (!this.row.free_fee)
      {
        if (this.row.trial_ends_at && moment(this.row.trial_ends_at).diff(moment()) > 0 || this.row.stripe_status == 'trialing') {
          res = 'In Trial'
        }
        if (this.row.stripe_status == 'canceled' && this.row.stripe_plan) {
          res = 'Canceled from Paid'
        }

        if (!this.row.stripe_plan && this.row.trial_ends_at && moment(this.row.trial_ends_at).diff(moment()) < 0) {
          res = 'Canceled from Trial'
        }
      } else {
        res = 'Free'  
      }

      return res
    }
  },
  methods: {
    onUpdateOpen(val) {
      this.openValue = val;
    },
    
    formatTime(value) {
      let userTimezone = moment.tz.guess()
      if (this.row && this.row.timezone)
        userTimezone = this.row.timezone
      if (value)
      {
        return moment.tz(value, userTimezone).format('MM-DD-YYYY hh:mm A')
      } else {
        return 'N/A'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
}

.trigger-span {
  cursor: pointer;
}
</style>