<template>
  <span class="status" v-bind:class="[formatedClass]">
    <strong>{{ formatedValue }}</strong>
  </span>
</template>

<script>
  export default {
    name: "BroadcastStatus",
    props: {
      value: {
        type: String,
        default: '',
      },
    },
    computed: {
      formatedValue() {
        let result = 'Scheduled'
        switch (this.value) {
          case 'completed':
            result = 'Completed'
            break;
          case 'in-progress':
            result = 'InProgress'
            break;
          case 'paused':
            result = 'Paused'
            break;
          case 'draft':
            result = 'Draft'
            break;
          default:
            break;
        }
        return result
      },
      formatedClass() {
        let result = 'text-primary'
        switch (this.value) {
          case 'completed':
            result = 'completed'
            break;
          case 'in-progress':
            result = 'text-primary'
            break;
          case 'paused':
            result = 'text-danger'
            break;
          case 'draft':
            result = ''
            break;
          default:
            break;
        }
        return result
      }
    }
  }
</script>

<style lang="scss" scoped>
  span.status {
    text-transform: capitalize;
    &.completed {
      color: green;
    }
  }
</style>
