<template>
  <a :href="`mailto:${value}`">{{ value }}</a>
</template>
<script>
export default {
  props: ['value']
}
</script>

<style scoped lang="scss">
  a {
    color: #3578c6;
    &:hover {
      text-decoration: underline;
    };
  }
</style>

