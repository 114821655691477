import { render, staticRenderFns } from "./td-CallStatus.vue?vue&type=template&id=79c0b896&scoped=true&"
import script from "./td-CallStatus.vue?vue&type=script&lang=js&"
export * from "./td-CallStatus.vue?vue&type=script&lang=js&"
import style0 from "./td-CallStatus.vue?vue&type=style&index=0&id=79c0b896&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c0b896",
  null
  
)

export default component.exports