<template>
  <vuestic-switch v-model="mutableValue" :disabled="disabled" style="max-width: 300px; margin: auto" :offcolor=true>
    <span slot="trueTitle">On</span>
    <span slot="falseTitle">Off</span>
  </vuestic-switch>
</template>
<script>
export default {
  props: {
    value: Boolean,
    xprops: Object,
    row: Object,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      mutableValue: Boolean(this.value)
    }
  },
  watch:  {
    mutableValue: function(val) {
      this.xprops.eventbus.$emit('toggleActive',{id: this.row.id, active: this.mutableValue});
    }
  }
};
</script>
