<template>
  <div class="d-flex align-middle justify-content-start">
    <div class="d-block">
      <audio v-if="url" ref="player" controls :src="url">
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <button v-if="url" @click="playpause" class="btn btn-default"><i class="fa"
          :class="{ 'fa-play': !playing, 'fa-pause': playing }" aria-hidden="true"></i></button>
      <br>
      <small v-if="duration">({{ currentPos }} / {{ duration }} s)</small>
    </div>
    <div v-if="url" class="d-block ml-3">
      <a :href="url" class="btn btn-primary btn-sm"><i class="fa white fa-download" aria-hidden="true" download></i></a>
    </div>
    <div v-if="transcription" class="d-block ml-3">
      <a href="javascript:;" @click="onShowTranscription" class="btn btn-primary btn-sm" v-b-tooltip.hover
        title="Show Transcription"><i class="fa white fa-eye" aria-hidden="true" download></i></a>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: ["row", "field", "xprops"],
  computed: {
    url() {
      return _.get(this.row.recording, 'url')
    },
    transcription() {
      return this.row.transcription
    }
  },
  mounted() {
    if (this.$refs.player) {
      this.$refs.player.addEventListener('play', (e) => {
        this.playing = true;
      })
      this.$refs.player.addEventListener('timeupdate', (e) => {
        this.currentPos = Math.round(this.$refs.player.currentTime)
      })
      this.$refs.player.addEventListener('pause', (e) => {
        this.playing = false;
        if (this.$refs.player.duration === this.$refs.player.currentTime) {
          this.$refs.player.currentTime = 0;
        }
      })
      this.$refs.player.addEventListener('canplay', (e) => {
        this.duration = Math.round(this.$refs.player.duration)
      })
    } else {
      this.duration = _.get(this.row.recording, 'duration')
    }
  },
  data() {
    return {
      playing: false,
      currentPos: 0,
      duration: 0
    }
  },
  methods: {
    onShowTranscription() {
      this.xprops.eventbus.$emit('onShowTranscription', this.row)
    },
    playpause() {
      try {
        if (this.$refs.player) {
          if (!this.playing) {
            this.$refs.player.play();
          } else {
            this.$refs.player.pause();
          }
        }
      } catch (e) {
      }
    }
  }
};
</script>

<style scoped>
audio {
  display: none
}

.fa {
  font-size: 1rem;
}

.white {
  color: white !important;
}

small {
  display: block;
  margin-top: -10px;
  margin-left: -8px;
}
</style>
