import DisplayRow from './nested-DisplayRow'
import Color from './td-Color'
import CreatetimeTd from './td-Createtime'
import CreatetimeTh from './th-Createtime'
import Email from './td-Email'
import IP from './td-IP'
import BusinessOpt from './BusinessOpt'
import FilterTh from './th-Filter'
import DefaultTd from './td-Default'
import ToggleTd from './td-Toggle'
import Vue from 'vue';
import AgencyOpt from './AgencyOpt'
import AgencyMore from './AgencyMore'
import PropertyValue from './PropertyValue'
import TdPhone from './Td-Phone'
import TdNestedValue from "./NestedValue"
import TdNestedYesNoValue from "./NestedYesNoValue"
import Recording from './Recording'
import TdNestedDateValue from './NestedDateValue'
import AccountsActiveToggleTd from './Accounts/ActiveToggleTd'
import TdBoolean from './td-Boolean'
import TdCallStatus from './td-CallStatus'
import TdJson from './td-Json'
import TdPlanMeta from './td-PlanMeta'
import TdContactType from './td-ContactType'
import ContactMore from './ContactMore'
import LandlineMore from './LandlineMore'
import TdTags from './td-Tags'
import TdTagsCount from './td-TagsCount'
import TaggedContactCount from './td-TaggedContactCount'
import TdTimestamp from './td-Timestamp'
import AutomationMore from './AutomationMore'
import TaskMore from './TaskMore'
import BroadcastMore from './BroadcastMore'
import TdBroadcastStatus from './td-BroadcastStatus'
import TdDay from './td-Day'
import ActiveToggleTd from './ActiveToggle'
import TdTimezone from './td-Timezone'
import TdLongText from './td-LongText'
import TdAgencyMeta from './td-AgencyMeta'
import ThReset from './th-Reset'
import TdVhtml from './td-vhtml'

// if some dynamic components are used frequently, a better way is to register them globally

const tableComponents = {
  DisplayRow,
  Color,
  CreatetimeTd,
  CreatetimeTh,
  Email,
  IP,
  PropertyValue,
  AgencyOpt,
  AgencyMore,
  BusinessOpt,
  FilterTh,
  DefaultTd,
  ToggleTd,
  TdPhone,
  TdNestedValue,
  TdNestedYesNoValue,
  Recording,
  TdNestedDateValue,
  AccountsActiveToggleTd,
  TdBoolean,
  TdCallStatus,
  TdJson,
  TdPlanMeta,
  TdContactType,
  ContactMore,
  TdTags,
  TdTagsCount,
  TaggedContactCount,
  TdTimestamp,
  BroadcastMore,
  AutomationMore,
  TaskMore,
  TdBroadcastStatus,
  TdDay,
  ActiveToggleTd,
  TdTimezone,
  LandlineMore,
  TdLongText,
  TdAgencyMeta,
  ThReset,
  TdVhtml,
};
for (let comp in tableComponents) {
  Vue.component(comp, tableComponents[comp])
}
export default tableComponents
